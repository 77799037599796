import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import WhiteButton from "./WhiteButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"


const StandardPromoBlock = () => (
  <div className={"flex justify-center"}>
  <div className={"flex justify-center text-center w-4/5 my-8 py-8 px-4 border border-black bg-orange "}>
    <div>
      <h1 className={"text-white"}>A faster website means better business.</h1>
      <h3 className={"text-white"}>Get a free site evaluation and speed up your site.</h3>
      <Link to={"/free-site-evaluation"}>

      <WhiteButton>Free Site Evaluation &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;
      </WhiteButton>
      </Link>
    </div>
  </div>
  </div>
)

StandardPromoBlock.propTypes = {
  siteTitle: PropTypes.string,
}

StandardPromoBlock.defaultProps = {
  siteTitle: ``,
}

export default StandardPromoBlock

